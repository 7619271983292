'use strict'

const _ = require('lodash')
const React = require('react')
const createReactClass = require('create-react-class')
const inlineContentMixin = require('../../mixins/inlineContentMixin')
const createReactElement = require('../../utils/createReactElement')
const santaTypesDefinitions = require('../../definitions/santaTypesDefinitions')

const MASTER_PAGE_PROPS_NOT_IN_DOM = ['fixedChildrenIDs', 'siteWidth', 'isPreviewMode', 'meshParams', 'browser', 'reportBI',
    'isMeshLayoutMechanism', 'isMobileView', 'compBehaviors', 'componentViewMode', 'defaultContentArea', 'isInSSR', 'childrenLayout', 'isCurrentPageLandingPage']

const getPlaceholderStyle = (compLayout, isCurrentPageLandingPage, isInSSR) => {
    const placeholderStyle = {
        height: isInSSR ? compLayout.height : 0
    }

    if (isCurrentPageLandingPage) {
        placeholderStyle.display = 'none'
    }

    return placeholderStyle
}

const getMasterPageChildrenGroups = (children, isMobileView) => {
    const structuralIDs = isMobileView ?
        {SITE_HEADER: true, SITE_FOOTER: true, PAGES_CONTAINER: true, BACK_TO_TOP_BUTTON: true, SOSP_CONTAINER_CUSTOM_ID: true} :
        {SITE_HEADER: true, SITE_FOOTER: true, PAGES_CONTAINER: true, BACK_TO_TOP_BUTTON: true}
    const isStructural = id => !!structuralIDs[id]
    const childrenArray = React.Children.toArray(children)

    const {
        pagesContainer,
        structural: {
            beforePagesContainer: structuralBeforePagesContainer,
            afterPagesContainer: structuralAfterPagesContainer
        }, soap: {
            beforePagesContainer: soapBeforePagesContainer,
            afterPagesContainer: soapAfterPagesContainer
        }
    } = childrenArray.reduce((acc, child) => {
        const id = child.props.id
        if (id === 'PAGES_CONTAINER') {
            acc.pagesContainer = child
        } else {
            const parentGroup = isStructural(id) ? acc.structural : acc.soap
            const subGroup = acc.pagesContainer ? parentGroup.afterPagesContainer : parentGroup.beforePagesContainer

            subGroup.push(child)
        }

        return acc
    }, {
        pagesContainer: null,
        soap: {
            beforePagesContainer: [],
            afterPagesContainer: []
        },
        structural: {
            beforePagesContainer: [],
            afterPagesContainer: []
        }
    })

    return {
        structuralBeforePagesContainer,
        soapBeforePagesContainer,
        pagesContainer,
        soapAfterPagesContainer,
        structuralAfterPagesContainer
    }
}

const getStyleForLegacyGaps = compData => {
    let legacyGapsStyle = ''
    if (compData) {
        const layoutSettings = compData.layoutSettings || {}
        if (layoutSettings.headerToPagesGap) {
            legacyGapsStyle += `#PAGES_CONTAINER {margin-top:${layoutSettings.headerToPagesGap}px;} `
        }
        if (layoutSettings.pagesToFooterGap) {
            legacyGapsStyle += `#PAGES_CONTAINER {margin-bottom:${layoutSettings.pagesToFooterGap}px;} `
        }
    }
    return !_.isEmpty(legacyGapsStyle) && React.createElement('style', {
        key: 'masterpage-legacy-gaps',
        dangerouslySetInnerHTML: {__html: legacyGapsStyle}
    })
}

const masterPage = {
    displayName: 'WixMasterPage',
    mixins: [inlineContentMixin],
    statics: {
        compType: 'mobile.core.components.MasterPage'
    },
    propTypes: {
        compData: santaTypesDefinitions.Component.compData,
        style: santaTypesDefinitions.Component.style,
        childrenLayout: santaTypesDefinitions.Component.childrenLayout,
        compBehaviors: santaTypesDefinitions.Component.compBehaviors,
        componentViewMode: santaTypesDefinitions.RenderFlags.componentViewMode.isRequired, // hack to get page behaviors to re-register when switching to preview
        defaultContentArea: santaTypesDefinitions.Container.defaultContentArea.isRequired,
        fixedChildrenIDs: santaTypesDefinitions.Component.fixedChildrenIDs,
        isInSSR: santaTypesDefinitions.isInSSR.isRequired,
        isMeshLayoutMechanism: santaTypesDefinitions.Layout.isMeshLayoutMechanism,
        isMobileView: santaTypesDefinitions.isMobileView,
        isCurrentPageLandingPage: santaTypesDefinitions.isCurrentPageLandingPage,
        meshParams: santaTypesDefinitions.Component.meshParams
    },

    renderMesh() {
        const {children, isMobileView, fixedChildrenIDs, isInSSR, childrenLayout, isCurrentPageLandingPage, compData} = this.props
        const getChildStructureLayout = childId => childrenLayout[childId] || {}

        const createPlaceholder = id => {
            if (!_.includes(fixedChildrenIDs, id)) {
                return null
            }

            return createReactElement('div', {key: `${id}-placeholder`, id: `${id}-placeholder`, style: getPlaceholderStyle(getChildStructureLayout(id), isCurrentPageLandingPage, isInSSR)})
        }

        const makeStructuralChildren = partialStructuralChildren =>
            _(partialStructuralChildren)
                .flatMap(p => [p, createPlaceholder(p.props.id)])
                .compact()
                .value()

        const {
            structuralBeforePagesContainer,
            soapBeforePagesContainer,
            pagesContainer,
            soapAfterPagesContainer,
            structuralAfterPagesContainer
        } = getMasterPageChildrenGroups(children, isMobileView)

        const headerHeight = getChildStructureLayout('SITE_HEADER').height
        const getMeshParamsForIds = compIds => {
            const idsMap = compIds.map(id => ({[id]: true})).reduce(_.assign)
            return this.props.meshParams.components
                .filter(({id}) => idsMap[id])
                .map(comp => _.assign(comp, {top: comp.top - headerHeight}))
        }

        const getIds = (childrenArr = []) => childrenArr.map(child => child.props.id)
        const getMeshParamsForSoapContainer = _.flow(getIds, getMeshParamsForIds)
        const getSoapContainer = (soapChildrenArray = [], id) => soapChildrenArray.length === 0 ?
            null :
            createReactElement(
                'div',
                {key: id, id},
                this.getChildrenRenderer({
                    contentArea: this.props.defaultContentArea,
                    overrides: {
                        id,
                        components: getMeshParamsForSoapContainer(soapChildrenArray),
                        fitToContentHeight: true
                    }
                })
            )

        return createReactElement(
            'div',
            {id: 'masterPage', className: 'mesh-layout', 'data-mesh-layout': 'grid'},
            [
                ...makeStructuralChildren(structuralBeforePagesContainer),
                getSoapContainer(soapBeforePagesContainer, 'soapBeforePagesContainer'),
                pagesContainer,
                getSoapContainer(soapAfterPagesContainer, 'soapAfterPagesContainer'),
                ...makeStructuralChildren(structuralAfterPagesContainer),
                !isMobileView && getStyleForLegacyGaps(compData)
            ]
        )
    },

    render() {
        return this.props.isMeshLayoutMechanism ?
            this.renderMesh() :
            createReactElement('div', _.omit(this.props || {}, MASTER_PAGE_PROPS_NOT_IN_DOM),
                this.getChildrenRenderer({contentArea: this.props.defaultContentArea}))
    }
}

module.exports = createReactClass(masterPage)