'use strict'

const FormContainer = require('./containers/FormContainer')

const containerFactory = require('./containers/containerFactory')
const LegacyContainer = containerFactory({defaultSkinName: 'wysiwyg.viewer.skins.area.DefaultAreaSkin', legacyInlineContent: true, includeScrollMixin: true, compType: 'wixapps.integration.components.Area'})
const GroupContainer = containerFactory({defaultSkinName: 'wysiwyg.viewer.components.GroupSkin', legacyInlineContent: false, includeScrollMixin: false, displayName: 'WixGroupContainer', compType: 'wysiwyg.viewer.components.Group'})
const Container = containerFactory({defaultSkinName: 'wysiwyg.viewer.skins.area.DefaultAreaSkin', legacyInlineContent: false, includeScrollMixin: true, compType: 'mobile.core.components.Container'})
const AppWidget = containerFactory({defaultSkinName: 'platform.components.skins.AppWidgetSkin', legacyInlineContent: false, includeScrollMixin: true, displayName: 'AppWidget', compType: 'platform.components.AppWidget'})

module.exports = {
    FormContainer,
    GroupContainer,
    LegacyContainer,
    Container,
    AppWidget
}
