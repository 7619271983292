'use strict'

const _ = require('lodash')
const PropTypes = require('prop-types')
const coreUtils = require('santa-core-utils')
const santaTypesDefinitions = require('../../definitions/santaTypesDefinitions')
const skinBasedComp = require('../skinBasedComp')
const skinsJson = require('./textSkins/skins.json.js')
/**
 * @class core.textCompMixin
 */

const {filterHtmlString} = coreUtils.xssUtils

const getCustomCompFonts = ({compData}) => compData ? coreUtils.fonts.collectFontsFromTextDataArray(compData.text) : []

getCustomCompFonts.fontsTypes = {
    compData: santaTypesDefinitions.Component.compData
}

function getDefaultSkinName() {
    return 'wysiwyg.viewer.skins.WRichTextNewSkin'
}

const skinsMap = _.pick(skinsJson, [getDefaultSkinName(), ..._.keys(skinsJson)])

const skinBasedCompMixin = skinBasedComp(skinsMap)
skinBasedCompMixin.statics.getCompFonts = getCustomCompFonts

const baseGetCompFonts = skinBasedCompMixin.statics.getCompFonts
skinBasedCompMixin.statics.getCompFonts = (styleIds, props) => {
    const defaultFonts = baseGetCompFonts(styleIds, props)
    const customFonts = getCustomCompFonts(props)

    return _.union(defaultFonts, customFonts)
}

skinBasedCompMixin.statics.getCompFonts.fontsTypes = _.assign(getCustomCompFonts.fontsTypes, skinBasedCompMixin.statics.getCompFonts.fontsTypes)

module.exports = {

    propTypes: {
        reportBI: santaTypesDefinitions.reportBI,
        id: santaTypesDefinitions.Component.id.isRequired,
        skin: santaTypesDefinitions.Component.skin.isRequired,
        style: santaTypesDefinitions.Component.style.isRequired,
        scale: santaTypesDefinitions.Component.scale,
        isPreviewMode: santaTypesDefinitions.isPreviewMode,
        isMobileView: santaTypesDefinitions.isMobileView.isRequired,
        title: PropTypes.string
    },

    mixins: [skinBasedCompMixin],

    statics: {
        getComponentSkins: () => skinsMap
    },

    getDefaultSkinName,

    componentWillMount() {
        this.updateHTML(this.props)
    },

    updateHTML(props) {
        this._componentHtml = filterHtmlString(props.compData.text || '', {allowIframes: this.allowIframes})
        this.convertCompDataTextToHTML(props)
    },

    componentWillReceiveProps(nextProps) {
        this.updateHTML(nextProps)
    },

    isPacked() {
        const isMobilePreview = this.props.isMobileView && this.props.isPreviewMode
        return _.get(this.props.compProp, 'packed', false) || isMobilePreview
    },

    getRootStyle(style) {
        const styleWithoutHeight = _.clone(style || {})
        if ((styleWithoutHeight['overflow-y'] || styleWithoutHeight.overflowY) !== 'hidden') {
            styleWithoutHeight.height = 'auto'
        }
        const minHeight = this.getMinHeight && this.getMinHeight()
        if (minHeight) {
            styleWithoutHeight.minHeight = minHeight
        }
        return styleWithoutHeight
    },

    getSkinProperties() { // eslint-disable-line complexity
        this.lastScale = this.props.scale || 1
        const skinName = _.has(skinsMap, this.props.skin) ? this.props.skin : this.getDefaultSkinName()
        const savedMinHeight = this.getMinHeight && this.getMinHeight()

        const style = this.getRootStyle(this.props.style)
        if (skinName === 'wysiwyg.viewer.skins.WRichTextNewSkin') {
            style.pointerEvents = 'none'
        }

        const refData = {
            '': {
                'data-packed': this.isPacked(),
                style
            }
        }
        if (savedMinHeight) {
            refData['']['data-min-height'] = savedMinHeight
        }

        let textContainer
        if (skinName === 'wysiwyg.viewer.skins.WRichTextSkin' || skinName === 'wysiwyg.viewer.skins.WRichTextClickableSkin') {
            textContainer = refData.richTextContainer = {}
        } else {
            textContainer = refData['']
        }

        if (_.isString(this._componentHtml)) {
            textContainer.dangerouslySetInnerHTML = {__html: this._componentHtml || ''}
        } else {
            textContainer.children = this._componentHtml
        }

        if (this.props.title) {
            refData[''].title = this.props.title
        }

        const overrideAlignment = _.get(this.props, ['compProp', 'overrideAlignment'])
        if (overrideAlignment) {
            textContainer.className = this.classSet(_.zipObject([`override-${overrideAlignment}`], [true]))
        }

        return refData
    }
}
