'use strict'

const _ = require('lodash')
const inlineContentMixin = require('../../../mixins/inlineContentMixin')
const scrollMixin = require('../../../mixins/scrollMixin/scrollMixin')
const skinsJson = require('../skins/skins.json')
const skinBasedComp = require('../../../mixins/skinBasedComp')
const createReactClass = require('create-react-class')

module.exports = ({defaultSkinName, legacyInlineContent = false, displayName = 'WixContainer', includeScrollMixin = false, compType}) => {
    // Create ordered skins where default skin is first
    const skins = _.pick(skinsJson, [defaultSkinName, ..._.keys(skinsJson)])

    return createReactClass({
        displayName,
    
        mixins: [
            ...includeScrollMixin ? [scrollMixin] : [], 
            skinBasedComp(skins), 
            ...legacyInlineContent ? [] : [inlineContentMixin]
        ],
    
        statics: {
            getComponentSkins: () => skins,
            compType
        },
    
        getSkinProperties() {
            return {
                inlineContent: {
                    children: legacyInlineContent ? this.props.children : this.getChildrenRenderer()
                }
            }
        }
    })
}
