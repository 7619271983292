'use strict'

const _ = require('lodash')
const skinsMap = require('./skins.json.js')
const buttonMixin = require('../../mixins/buttonMixin')
const skinBasedComp = require('../../mixins/skinBasedComp')
const santaTypesDefinitions = require('../../definitions/santaTypesDefinitions')
const createReactElement = require('../../utils/createReactElement')
const coreUtilsLib = require('santa-core-utils')
const createReactClass = require('create-react-class')

/**
 * @class components.siteButton
 * @extends {core.skinBasedComp}
 */

const siteButton = createReactClass({
    displayName: 'SiteButton',
    mixins: [skinBasedComp(skinsMap), buttonMixin(skinsMap)],

    propTypes: {
        structure: santaTypesDefinitions.Component.structure.isRequired,
        compData: santaTypesDefinitions.Component.compData,
        registerLayoutFunc: santaTypesDefinitions.Layout.registerLayoutFunc.isRequired,
        isMobileDevice: santaTypesDefinitions.Device.isMobileDevice,
        isMobileView: santaTypesDefinitions.isMobileView,
        link: santaTypesDefinitions.SiteButton.link,
        impliedLink: santaTypesDefinitions.SiteButton.impliedLink
    },

    statics: {
        compSpecificIsDomOnlyOverride() {
            return false
        },
        getComponentSkins: () => skinsMap,
        compType: 'wysiwyg.viewer.components.SiteButton'
    },

    getDefaultProps() {
        return {
            shouldUseFlex: true
        }
    },

    getInitialState() {
        return {
            $mobile: this.props.isMobileDevice ? 'mobile' : 'desktop',
            $shouldUseFlex: this.props.shouldUseFlex ? 'shouldUseFlex' : '',
            $align: this.props.compProp.align
        }
    },

    componentWillReceiveProps(nextProps) {
        if (this.state.$align !== nextProps.compProp.align) {
            this.setState({
                $align: nextProps.compProp.align
            })
        }
    },

    getImpliedLink() {
        return this.props.impliedLink
    },

    getLinkSkinPart() {
        const compProps = this.props.compProp
        const noLink = {parentConst: createReactElement.bind(null, 'div')}

        if (compProps.isDisabled) {
            return noLink
        }

        if (this.props.link) {
            const linkSkinProps = this.props.link
            const additionPropsForAccessibility = {
                role: 'button',
                onKeyDown: coreUtilsLib.accessibility.keyboardInteractions.activateBySpaceButton
            }
            if (linkSkinProps.parentConstType) {
                additionPropsForAccessibility.parentConst = createReactElement.bind(null, linkSkinProps.parentConstType)
                delete linkSkinProps.parentConstType
            }
            return _.assign(linkSkinProps, additionPropsForAccessibility)
        }

        return this.getImpliedLink() || noLink
    },

    getSkinProperties() {
        this.lastScale = this.props.scale || 1
        const compData = this.props.compData
        const compProps = this.props.compProp
        const isDisabled = !!compProps.isDisabled

        const linkSkinPart = this.getLinkSkinPart()
        linkSkinPart.style = {}
        if (!this.props.shouldUseFlex) {
            linkSkinPart.style.textAlign = compProps.align
        }

        const skinProps = {
            '': {
                id: this.props.id,
                key: `f${this.getDesktopFontSize() * this.currentScale}`,
                ref: this.props.id,
                'data-align': compProps.align,
                'data-disabled': isDisabled,
                'data-margin': compProps.margin,
                'data-should-use-flex': this.props.shouldUseFlex,
                'data-width': _.get(this.props.structure, 'layout.width', 0),
                'data-height': _.get(this.props.structure, 'layout.height', 0)
            },
            label: {
                children: [compData.label],
                style: this.getLabelStyle()
            },
            link: linkSkinPart
        }

        if (this.props.onClick && !isDisabled) {
            skinProps[''].onClick = this.props.onClick
        }

        this.resetMinHeightIfNeeded(skinProps)
        return skinProps
    }
})

module.exports = siteButton
